:root {
	--app-background: #eae6df;
	--app-background-deeper: #d1d7db;
	--app-body-background: #ededed;
}

.app {
	display: grid;
	place-items: center;
	background-color: var(--app-background);
	background-image: linear-gradient(180deg, var(--app-background), var(--app-background-deeper));
	height: 100vh;
}

.app__body {
	display: flex;
	background-color: var(--app-body-background);
	height: 90vh;
	width: 90vw;
	max-width: 1600px;
	box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7);
}
