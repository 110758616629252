.sidebarChat {
	display: flex;
	padding: 20px;
	cursor: pointer;
	border-bottom: 1px solid #f6f6f6;
}

.sidebarChat:hover {
	background-color: #ebebeb;
}

.sidebarChat__info {
	margin-left: 15px;

	h2 {
		font-size: 16px;
	}

	p {
		font-size: 14px;
		color: grey;
		font-style: italic;
	}
}
