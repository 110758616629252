.chat {
	display: flex;
	flex-direction: column;
	flex: 0.7;
}

@media screen and (max-width: 650px) {
	.chat {
		flex: 1;
	}
}

.chat__header {
	display: flex;
	align-items: center;
	padding: 20px;
	background-color: var(--app-body-background);
	border-bottom: 1px solid lightgray;
}

.chat__headerInfo {
	flex: 1;
	padding-left: 20px;

	h3 {
		font-size: 16px;
		font-weight: 500;
	}

	p {
		font-size: 12px;
		color: gray;
		font-style: italic;
	}
}

.chat__headerRight {
	display: flex;
	justify-content: space-between;
	min-width: 100px;
}

.chat__body {
	position: relative;
	flex: 1;
	background: #efeae2;

	.background,
	.content {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.background {
		background-image: url('../../assets/images/background.png');
		opacity: 0.4;
		z-index: 1;
	}

	.content {
		padding: 30px 70px;
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 2;

		.messages {
			margin-bottom: 20px;

			.message {
				position: relative;
				width: fit-content;
				max-width: 65%;
				margin-bottom: 3px;
				padding: 8px 10px;
				background-color: white;

				border-radius: 7.5px;
				box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);

				.text {
					font-size: 14px;
				}

				.timestamp {
					position: relative;
					bottom: 0;
					right: 0;
					float: right;
					margin: 10px 0 0 5px;
					font-size: 11px;
					color: grey;
				}
			}

			.message::after {
				content: '';
				clear: both;
				display: table;
			}

			.message.own {
				margin-left: auto;
				background-color: #d9fdd3;
			}
		}
	}

	@media screen and (max-width: 650px) {
		.content {
			padding: 25px;
		}

		.message {
			max-width: 85% !important;
		}
	}
}

.chat__footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 10px;
	background-color: var(--app-body-background);
	border-top: 1px solid lightgray;

	form {
		display: flex;
		flex: 1;

		input {
			flex: 1;
			margin: 0 10px;
			padding: 10px 20px;
			border: none;
			outline: none;
			border-radius: 8px;
		}

		button {
			display: none;
		}
	}
}
