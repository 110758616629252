.sidebar {
	display: flex;
	flex-direction: column;
	flex: 0.3;
	border-right: 1px solid lightgray;
}

@media screen and (max-width: 650px) {
	.sidebar {
		display: none;
		flex: 0;
	}
}

.sidebar__header {
	display: flex;
	justify-content: space-between;
	padding: 20px;
	background-color: var(--app-body-background);
	border-bottom: 1px solid lightgray;
}

.sidebar__headerRight {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: 5vw;
}

.sidebar__search {
	display: flex;
	align-items: center;
	background-color: #f6f6f6;
	height: 39px;
	padding: 10px;
}

.sidebar__searchContainer {
	display: flex;
	align-items: center;
	background-color: white;
	padding: 0 15px 0 5px;
	width: 100%;
	height: 35px;
	border-radius: 5px;

	.searchIcon {
		color: rgba(0, 0, 0, 0.54);
		padding: 10px;
	}

	input {
		border: none;
		margin-left: 10px;
		width: 100%;
		outline: none;
	}
}

.sidebar__chats {
	flex: 1;
	background-color: white;
	overflow-x: hidden;
	overflow-y: auto;
}
